<template>
  <div class="mainContent" style="min-height: 400px">
    <div class="flex flex-column w-full mb-3">
      <Breadcrumb
        :home="home"
        :model="breadcrumb"
        style="background: transparent !important"
      >
        <template #item="{ item }">
          <routerLink
            v-if="item.route"
            v-slot="{ navigate }"
            :to="item.route"
            custom
          >
            <a @click="navigate">
              <span>{{ item.label }}</span>
            </a>
          </routerLink>
        </template>
      </Breadcrumb>
    </div>
    <h2>{{ title }}</h2>
    <div class="grid">
      <div
        v-for="(item, index) in filenames"
        :key="index"
        class="col"
        style="margin-top: 5rem"
      >
        <Card
          class="md:col-12 lg:col-2 xl:col-2 col-12 surface-overlay shadow-6 sm:col-12 flex-order-0 md:flex-order-0 lg:flex-order-1 xl:flex-order-1 sm:flex-order-0 w-full"
        >
          <template #title>{{ item.title }}</template>
          <template #footer
            ><Button
              label="Download"
              icon="pi pi-file-pdf"
              @click="downloadFile(item.filename)"
            ></Button
          ></template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from "primevue/card";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import { ref } from "vue";
import { DocumentService } from "@/apis/ceresep";

let title = ref("Price Lists");
let isLoading = ref(false);

const home = ref({
  label: "Home",
  route: "/",
});

const breadcrumb = [{ label: title.value, route: "/price-lists" }];

const filenames = [
  {
    title: "Partner Price List Memory",
    filename: "Partner_Price_List_Memory.pdf",
  },
  {
    title: "Partner Sales Price Liste Hypervisor",
    filename:
      "Partner_Sales_Price_Liste_Hypervisor.pdf",
  },
  {
    title: "Partner Price List",
    filename: "Partner_Price_List_July.pdf",
  },
];

async function downloadFile(filename: string) {
  let res = await DocumentService.getApiV1DocumentsPricelist(filename);

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:" + res.mimetype + ";base64, " + encodeURIComponent(res.content!)
  );
  element.setAttribute("download", res.filename!);
  document.body.appendChild(element);
  element.click();
}
</script>
