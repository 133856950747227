/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCompaniesFromTokenRequest } from '../models/GetCompaniesFromTokenRequest';
import type { GetCompaniesFromTokenResponse } from '../models/GetCompaniesFromTokenResponse';
import type { ListCompaniesResponse } from '../models/ListCompaniesResponse';
import type { SapCompanyFullSyncResponse } from '../models/SapCompanyFullSyncResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyService {

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListCompaniesResponse OK
     * @throws ApiError
     */
    public static getApiV1CompanyList(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListCompaniesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Company/List',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param request 
     * @returns GetCompaniesFromTokenResponse OK
     * @throws ApiError
     */
    public static getApiV1CompanyGetCompanies(
request?: GetCompaniesFromTokenRequest,
): CancelablePromise<GetCompaniesFromTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Company/GetCompanies',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param startAt 
     * @param syncMax 
     * @param step 
     * @returns SapCompanyFullSyncResponse OK
     * @throws ApiError
     */
    public static putApiV1CompanySync(
startAt?: number,
syncMax?: number,
step?: number,
): CancelablePromise<SapCompanyFullSyncResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Company/sync',
            query: {
                'StartAt': startAt,
                'SyncMax': syncMax,
                'Step': step,
            },
        });
    }

}
