/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactSalesEmailResponse } from '../models/ContactSalesEmailResponse';
import type { ContactsResponse } from '../models/ContactsResponse';
import type { CreateAccountEmailRequest } from '../models/CreateAccountEmailRequest';
import type { CreateAccountEmailResponse } from '../models/CreateAccountEmailResponse';
import type { SendEmailRequest_Email } from '../models/SendEmailRequest_Email';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmailService {

    /**
     * @param companyId 
     * @returns ContactsResponse OK
     * @throws ApiError
     */
    public static getApiV1EmailContacts(
companyId: number,
): CancelablePromise<ContactsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/{CompanyId}/Contacts',
            path: {
                'CompanyId': companyId,
            },
        });
    }

    /**
     * @param companyId 
     * @param requestBody 
     * @returns ContactSalesEmailResponse OK
     * @throws ApiError
     */
    public static postApiV1EmailSendEmail(
companyId: number,
requestBody?: SendEmailRequest_Email,
): CancelablePromise<ContactSalesEmailResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Email/{CompanyId}/SendEmail',
            path: {
                'CompanyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId 
     * @param requestBody 
     * @returns CreateAccountEmailResponse OK
     * @throws ApiError
     */
    public static postApiV1EmailNotifyCreatedAccount(
companyId: string,
requestBody?: CreateAccountEmailRequest,
): CancelablePromise<CreateAccountEmailResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Email/{CompanyId}/NotifyCreatedAccount',
            path: {
                'CompanyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
