<template>
  <div class="mainContent" style="min-height:400px;">
    <div class="flex flex-column w-full mb-3">
      <Breadcrumb :home="{ label: 'Home', command: to('/') }"
        :model="[{ label: 'Order Backlog', command: to('/orders') }]"
        style="background: transparent !important; "></Breadcrumb>
    </div>
    <ConDataTable tableId="OrderList" :isLoading="isLoading" title="Order Backlog" :columns="orderTableColumns"
      class="w-full mb-5" :expansionColumns="orderTableExpansionColumns" filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchOrderDataAsync" ref="orderTableRef" expansionKey="salesOrderItemList"
      filterType="odata">

    </ConDataTable>
  </div>
</template>
<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import { ref } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import { SapService } from '@/apis/ceresep/services/SapService';
import { to } from '@congatec/primevue-components-lib';
import { useCompanyStore } from '@/stores/company-store';
import { formatDate, formatDecimal, mapSapValue, orderBacklogMapper } from '@/text/sap';


const orderTableRef = ref<null | typeof ConDataTable>(null);
const isLoading = ref(false);
const orderTableColumns = [
  {
    expander: true,
    field: "expansion",
    header: "",
  },
  {
    field: "salesOrder",
    header: "Order",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
    path: "to_SalesOrder/",
    class: 'text-left'
  },
  {
    field: "creationDate",
    header: 'Created Date',
    dataType: 'date',
    onFormat: formatDate,
    isFiltered: true,
    sortable: true,
    path: "to_SalesOrder/",
    class: 'text-left'
  },
  {
    field: "overallTotalDeliveryStatus",
    header: "Delivery Status",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    path: "to_SalesOrder/",
    class: 'text-left'
  },
  {
    field: "purchaseOrderByCustomer",
    header: "Customer Reference",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    path: "to_SalesOrder/",
    class: 'text-left',
  },
  {
    field: "totalNetAmount",
    header: "Sales Volume",
    onFormat: formatDecimal,
    dataType: "numeric",
    path: "to_SalesOrder/",
    class: 'text-right',
    headerClass: 'column-text-right',
  },
  {
    field: "transactionCurrency",
    header: "Transaction Currency",
    dataType: "text",
    isFiltered: false,
    sortable: false,
    path: "to_SalesOrder/",
    class: 'text-left',
  },
]

const orderTableExpansionColumns = [
  {
    field: "position",
    header: "Position",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "material",
    header: "Material",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "salesOrderItemText",
    header: "Description",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "requestedQuantity",
    header: "Quantity",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "unitPrice",
    header: "Unit Price",
    onFormat: formatDecimal,
    dataType: "text",
    class: 'text-right'
  },
  {
    field: "netAmount",
    header: "Sales Volume",
    onFormat: formatDecimal,
    dataType: "text",
    class: 'text-right'
  },
  {
    field: "requestedDeliveryDate",
    header: "Requested Delivery Date",
    dataType: "date",
    onFormat: (x: Date) => {
      if (!x) {
        return 'N/A';
      }
      return formatDate(x);
    },
    class: 'text-left'
  },
  {
    field: "deliveryStatus",
    header: "Delivery Status",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "shippedDate",
    header: "Shipped on Date",
    dataType: "date",
    onFormat: (x: Date) => {
      if (!x) {
        return 'N/A';
      }
      return formatDate(x);
    },
    class: 'text-left'
  }
]

//functions area
async function fetchOrderDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {
  const company = useCompanyStore();
  const res = await SapService.getApiV1SapSalesOrder(
    company.selectedCompany?.id || 0,
    filters + 'overallTotalDeliveryStatus!=C',
    sorts || '-creationDate',
    page,
    rows,
    false
  );
  res.salesOrders?.forEach(mapSapValue(orderBacklogMapper));
  res.salesOrders?.forEach((x: any) => {
    x.salesOrderItemList.forEach(mapSapValue(orderBacklogMapper));
  });
  console.log("Expansion values:", res.salesOrders)
  return {
    totalCount: res.totalCount,
    data: res.salesOrders,
  };
}
</script>
<style scoped></style>
