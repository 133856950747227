import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from "@vueuse/core";

const STORAGE_KEY = "ceres-selected-company";

export const useCompanyStore = defineStore('company-store', () => {
  const selectedCompany: Ref<Company | undefined | null> = useStorage(STORAGE_KEY, {} as Company); 

  const setCompany = (newCompany: Company) =>{
    selectedCompany.value = newCompany;
    console.log("Current Company",selectedCompany.value);
  }

  return { selectedCompany, setCompany };
});

export type Company = {
    id : number,
    companyName: string,
    sapCompanyId: string
}
