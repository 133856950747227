/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConvertDocumentToImagesResponse } from '../models/ConvertDocumentToImagesResponse';
import type { DocumentType } from '../models/DocumentType';
import type { DownloadRoadmapResponse } from '../models/DownloadRoadmapResponse';
import type { ListRoadmapsRequest } from '../models/ListRoadmapsRequest';
import type { ListRoadmapsResponse } from '../models/ListRoadmapsResponse';
import type { PricelistDownloadResponse } from '../models/PricelistDownloadResponse';
import type { UploadDocumentResponse } from '../models/UploadDocumentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * @param filename 
     * @returns PricelistDownloadResponse OK
     * @throws ApiError
     */
    public static getApiV1DocumentsPricelist(
filename?: string,
): CancelablePromise<PricelistDownloadResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/pricelist',
            query: {
                'Filename': filename,
            },
        });
    }

    /**
     * @param documentType 
     * @param formData 
     * @returns UploadDocumentResponse OK
     * @throws ApiError
     */
    public static postApiV1Documents(
documentType?: DocumentType,
formData?: {
FileData?: Blob;
},
): CancelablePromise<UploadDocumentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents',
            query: {
                'DocumentType': documentType,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param request 
     * @returns ListRoadmapsResponse OK
     * @throws ApiError
     */
    public static getApiV1Documents(
request?: ListRoadmapsRequest,
): CancelablePromise<ListRoadmapsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param filename 
     * @returns DownloadRoadmapResponse OK
     * @throws ApiError
     */
    public static getApiV1DocumentsRoadmap(
filename?: string,
): CancelablePromise<DownloadRoadmapResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/roadmap',
            query: {
                'Filename': filename,
            },
        });
    }

    /**
     * @param filename 
     * @returns ConvertDocumentToImagesResponse OK
     * @throws ApiError
     */
    public static getApiV1DocumentsImages(
filename?: string,
): CancelablePromise<ConvertDocumentToImagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/images',
            query: {
                'Filename': filename,
            },
        });
    }

}
