<template>
    <!-- <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" header="Document Upload"
        @update:visible="closeDialog" position="top" class="w-7">
        <template #default> -->
    <div class="flex flex-column w-full h-full min-h-screen py-2">
        <div class="flex flex-column w-full mb-2">
            <Breadcrumb :home="{ label: 'Home', command: to('/') }" :model="[{ label: 'Document-Upload' }]"
                style="background: transparent !important;" class="p-0">
            </Breadcrumb>
        </div>
        <div class="text-800 font-semibold text-3xl mb-2 text-center">Document Upload</div>
        <div class="text-700 text-xl mb-3 text-center ">Here you can upload documents for your site, for example pricelists
            and roadmaps</div>
        <div class="flex flex-column w-full">
            <FileUpload class="a-uploadprogress" :disabled="false" name="ceresRoadmap[]" ref="fileUpload" customUpload
                @uploader="onFileUpload" :multiple="true" @select="onFileSelect" :maxFileSize="100000000000"
                @progress="showProgress">
                <template #header="{ chooseCallback, clearCallback, files }">
                    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="flex gap-2">
                            <Button @click="chooseCallback()" icon="pi pi-folder-open" rounded outlined></Button>
                            <Button @click="onFileUpload($event)" icon="pi pi-cloud-upload" rounded outlined
                                severity="success" :disabled="!files || files.length === 0"></Button>
                            <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger"
                                :disabled="!files || files.length === 0"></Button>
                        </div>
                        <div class="w-auto flex flex-column">
                            <label for="ruletype">Document Type </label>
                            <Dropdown id="ruletype" v-model="documentType" :options="Object.values(DocumentType)"
                                placeholder="Select Document Type" class="w-full md:w-14rem" />
                        </div>
                        <div class="flex align-content-center align-items-center gap-3">
                            <div>
                                <label>Upload</label>
                                <ProgressBar :value="totalSizePercent" :class="['md:w-20rem h-1rem w-full md:ml-auto']">
                                    {{ totalSizePercent }}%
                                    <!-- <span>{{ totalSize }}B / 1Mb</span> -->
                                </ProgressBar>

                                <label class="mt-2">Processing</label>
                                <ProgressBar :value="100" :showValue="false" :mode="processingMode as any"
                                    :class="['md:w-20rem h-1rem w-full md:ml-auto']">
                                    {{ totalProcessingSizePercent }}%
                                    <!-- <span>{{ totalSize }}B / 1Mb</span> -->
                                </ProgressBar>
                            </div>
                            <ProgressSpinner v-if="uploadInProgress" style="width: 40px; height: 40px" strokeWidth="8"
                                fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner"
                                v-tooltip.left="'Server upload in progress'" />
                        </div>
                    </div>
                </template>
                <template #empty>
                    <p>Drag and drop files here to upload. <span>*</span></p>
                </template>
            </FileUpload>
        </div>
    </div>
    <!-- </template>
    </Dialog> -->
</template>
<script setup lang="ts">
import { to } from "@congatec/primevue-components-lib";
import Breadcrumb from 'primevue/breadcrumb';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { type Ref, onMounted, ref, watch } from 'vue';
import FileUpload from "primevue/fileupload";
import { useToast } from "primevue/usetoast";
import ProgressBar from "primevue/progressbar";
import { useEventBroker } from "@congatec/eventbroker-lib";
import ProgressSpinner from "primevue/progressspinner";
import { DocumentService, DocumentType } from '@/apis/ceresep';
import Dropdown from 'primevue/dropdown';

const eventBroker = useEventBroker();
eventBroker?.subscribe("/upload-progress", async (percentCompleted) => {
    totalSizePercent.value = Math.floor(percentCompleted.progress * 100);
});

const processingMode = ref("determinate");
const toast = useToast();
const files: Ref<any[]> = ref([]);
const totalSize = ref(0);
const totalSizePercent = ref(0);
const totalProcessingSizePercent = ref(0);
const progress = ref(0);
const uploadInProgress = ref(false);
const fileUpload = ref();
const documentType: Ref<DocumentType> = ref(DocumentType.ROADMAP);

//const dialogVisible = ref(false);
const state = ref({
    FileUploadBase64: [
        { FileName: new String(null), FileContent: new String(null) },
    ],
});

//props definition
// const props = defineProps<{
//     visible: boolean,
// }>();

const emits = defineEmits(['onDialogClose', 'createOrUpdate']);

// const closeDialog = () => {
//     dialogVisible.value = false;
//     emits('onDialogClose');
// }

//watch area
// watch(() => props.visible, async (nv: any) => {
//     if (nv != undefined) {
//         dialogVisible.value = nv;
//     }
// }, { deep: true, immediate: true });


// functions area
const onFileUpload = async (event: any) => {
    uploadInProgress.value = true;
    state.value.FileUploadBase64 = [];

    try {
        processingMode.value = "indeterminate"
        console.log("fileUploadEvent: ", event);
        if (files.value) {
            //const formData = new FormData();
            await Promise.all(
                files.value.map(async (file, index) => {
                    //formData.append('files', file);
                    //await convertToBase64(file);

                    await DocumentService.postApiV1Documents(
                        documentType.value,
                        { FileData: file } as any,
                    );
                    totalSizePercent.value = 100;
                    fileUpload.value.uploadedFiles.push(file);
                }),
            );
            //console.log("uploadedData: ", formData);
            // set seleced files as uploadedFiles so that the status badge shows as completed
            fileUpload.value.files = [];
            uploadInProgress.value = false;
            toast.add({
                severity: "info",
                summary: "Success",
                detail: "File Uploaded",
                life: 3000,
            });
        }
    } finally {
        processingMode.value = "determinate"
        uploadInProgress.value = false;
    }
    //callback();
};

const onFileSelect = (event: any) => {
    console.log("selectEvent: ", event);
    totalSize.value = 0;
    if (event.files) {
        files.value = event.files;
        files.value.forEach((file) => {
            totalSize.value += file.size ?? 0;
        });
    }
    totalSizePercent.value = 0;
    totalProcessingSizePercent.value = 0;

    //   imageUrl.value = event.files[0].objectURL;
    //   // set seleced files as uploadedFiles so that the status badge shows as completed
    //   imageUpload.value.uploadedFiles = imageUpload.value.files;
    //   imageUpload.value.files = [];
    //   toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    //   convertToBase64(event.files[0]);
};

const showProgress = (event: any) => {
    totalSizePercent.value =
        (event.originalEvent.loaded * 100) / event.originalEvent.total;
    progress.value = event.originalEvent.value;
};

</script>
<style>
/* .p-fileupload .p-progressbar {
  height: 1rem !important;
  display: none;
} */

.p-progressbar.p-progressbar-determinate .p-progressbar-label {
    display: flex;
}

.p-fileupload-content .p-progressbar {
    display: none;
}
</style>