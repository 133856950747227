<template>
  <link rel="stylesheet" :href="theme" />
  <div class="flex flex-column align-items-center w-full h-full surface-card">
    <Toast />
    <Toolbar id="app-toolbar-lg" class="h-full w-full h-3rem  p-0 toolbarClass lg:flex md:hidden hidden sm:hidden"
      style="border-bottom: 2px solid var(--primary-color); z-index:1001;">
      <template #center>
        <div class="flex flex-row h-full w-11 justify-content-between pb-2">
          <div class="flex align-items-end  h-full w-2">
            <img src="/general_images/footer_logo.png" class="cursor-pointer" @click="router.push('/')" />
          </div>
          <div class="flex flex-row h-full w-6 align-items-end justify-content-center gap-5">
            <a href="https://www.congatec.com/de/congatec" style="text-decoration:none;">
              <Button label="congatec" text rounded
                class="text-color-secondary p-0 text-xl toolbar-global-button"></Button>
            </a>
            <Button text rounded label="contact us" class="text-color-secondary p-0 text-xl toolbar-global-button"
              @click="router.push('/contact')">
            </Button>
            <Dropdown filter class="w-6 h-2rem" id="currentCompany" v-model="selectedCompany" :options="companies"
              optionLabel="companyName" v-if="companyLength > 1">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center" v-tooltip.bottom="{
                  value: 'Select a company',
                  pt: {
                    arrow: {
                      style: {
                        borderBottomColor: 'var(--primary-color)',
                      }
                    },
                    text: 'bg-primary font-medium m-0'
                  }
                }">
                  <div>{{ slotProps.value.companyName }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center h-full" type="text" placeholder="right" v-tooltip="{
                  value: `Information: \n Company Id: ${slotProps.option.sapCompanyId}`,
                  pt: {
                    arrow: {
                      style: {
                        borderRightColor: 'var(--primary-color)',
                      }
                    },
                    text: 'bg-primary font-medium m-0'
                  }
                }">
                  <div>{{ slotProps.option.companyName }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="h-full w-3 flex-column justify-content-end align-items-end h-full gap-6 flex">
            <!-- <div class="flex flex-row gap-5" style="align-items: flex-start; ">
              <Dropdown v-if="selectedLanguage.length > 0" class="flex text-color-secondary text-sm"
                v-model="selectedLanguage" :options="languages" optionLabel="name" placeholder="Select a Language"
                unstyled />
            </div> -->
            <div class="flex flex-row align-items-end justify-content-end w-full gap-3">
              <AutoComplete id="search-bar" class="h-2rem border-1 border-700 border-round" v-model="selectedSearchItem"
                optionLabel="name" placeholder="Search" :suggestions="suggestions" @complete="search"
                @item-select="() => router.push(selectedSearchItem.link)">
              </AutoComplete>
              <ConUserButton :items="adminItem" :key="userItemCounter" />



            </div>
          </div>
        </div>
      </template>
    </Toolbar>
    <Toolbar id="app-toolbar-md-below" class="h-full w-full h-3rem p-0 toolbarClass-sm lg:hidden md:flex flex sm:flex"
      style="border-bottom: 2px solid var(--primary-color); z-index:1001;">
      <template #start>
        <div class="w-full flex flex-column pl-5">
          <img src="/general_images/footer_logo.png" class="cursor-pointer" @click="() => {
            smSidebarVisible = true
          }" />
        </div>
      </template>
      <template #end>
        <div class="w-full flex flex-column pr-5">
          <ConUserButton :items="adminItem" :key="userItemCounter" />
        </div>
      </template>
    </Toolbar>
    <Sidebar v-model:visible="smSidebarVisible" header=" "
      class="w-full md:flex sm:flex md:w-full sm:w-full lg:hidden flex surface-0 text-xl" style="height:92%">
      <div class="flex flex-column w-full align-items-center">
        <div class="flex flex-column gap-5 w-6 text-center">
          <div class="flex flex-column w-full text-center align-items-center">
            <Button text rounded label="Home" class="p-0 text-xl text-primary" @click="() => {
              router.push('/');
              smSidebarVisible = false;
            }">
            </Button>
          </div>
          <div class="flex flex-column w-full text-center align-items-center gap-3">
            <a href="https://www.congatec.com/de/congatec" style="text-decoration:none;">
              <Button label="congatec Homepage" text rounded class="text-color-secondary p-0 text-xl"></Button>
            </a>
            <Button text rounded label="contact us" class="text-color-secondary p-0 text-xl toolbar-global-button"
              @click="() => {
                router.push('/contact');
                smSidebarVisible = false;
              }">
            </Button>
              <AutoComplete id="search-bar-sm" class="h-3rem w-full border-1 border-700 border-round" v-model="selectedSearchItem"
                optionLabel="name" placeholder="Search Page" :suggestions="suggestions" @complete="search"
                @item-select="() => router.push(selectedSearchItem.link)">
              </AutoComplete>
              <Dropdown class="w-full h-2rem" id="currentCompany" v-model="selectedCompany" :options="companies"
              optionLabel="companyName" v-if="companyLength > 1">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center" v-tooltip.bottom="{
                  value: 'Select a company',
                  pt: {
                    arrow: {
                      style: {
                        borderBottomColor: 'var(--primary-color)',
                      }
                    },
                    text: 'bg-primary font-medium m-0'
                  }
                }">
                  <div>{{ slotProps.value.companyName }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center h-full" type="text">
                  <div>{{ slotProps.option.companyName }}</div>
                </div>
              </template>
            </Dropdown>
          </div>


        </div>
      </div>
    </Sidebar>
    <div class="lg:w-11 sm:w-full md:w-full sm:px-5 md:px-5 lg:px-0 px-5 w-full h-full">
      <RouterView :key="remountMe" class="h-full " style="min-height: calc(100vh - 25.9rem );"></RouterView>
    </div>

    <div class="flex w-full h-full">
      <FooterBar></FooterBar>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ConUserButton, to } from "@congatec/primevue-components-lib";
import { RouterView } from "vue-router";
import {
  useThemeStore,
  useBreadCrumbStore
} from "@congatec/primevue-components-lib";
import { type KeycloakService, onKeycloakToken, useRequestErrorGuard } from "@congatec/authentication-lib";
import { Theme } from "@congatec/primevue-components-lib";
import Button from "primevue/button";
import "../node_modules/@congatec/primevue-components-lib/dist/lib/style.css";
import { onMounted, ref, watch } from "vue";
import Toolbar from "primevue/toolbar";
import FooterBar from "./components/footer/FooterBar.vue";
import { useRouter } from "vue-router";
import Dropdown from "primevue/dropdown";
import { CompanyService } from "./apis/ceresep";
import { useCompanyStore } from "./stores/company-store";
import AutoComplete from 'primevue/autocomplete';
import type { MenuItem } from "primevue/menuitem";
import Toast from "primevue/toast";
import axios from "axios";
import { useToast } from "primevue/usetoast";
import Sidebar from "primevue/sidebar";

const toast = useToast();
const smSidebarVisible = ref(false);
const selectedLanguage = ref("");
const languages = ref(['test']);
const companyStore = useCompanyStore()
const adminItem = ref<MenuItem[]>([]);
const companies = ref<any | undefined>();
const selectedCompany = ref();
const companyLength = ref(0)
const selectedSearchItem = ref();
const suggestions = ref<any[]>([]);
const userItemCounter = ref(0);
const searchItems = ref([
  {
    name: "Opportunity",
    link: "/opp"
  },
  {
    name: "Order Backlog",
    link: "/orders"
  },
  {
    name: "Payment History",
    link: "/invoices"
  },
  {
    name: "Sample Availability",
    link: "/sample-availability"
  },
]);
const router = useRouter();
const breadCrumbStore = useBreadCrumbStore();
const remountMe = ref(0);

onKeycloakToken(async (authenticationService: KeycloakService) => {
  if (authenticationService.hasRoles(['CreateUser', 'EditUser'], import.meta.env.VITE_KEYCLOAK_CLIENTID)) {
    adminItem.value.push({
      label: "Admin Portal",
      icon: "pi pi-user",
      command: to('/admin')
    })
    userItemCounter.value++;
  }
  if (authenticationService.hasRoles(['UploadRoadmap', 'SalesAdmin'], import.meta.env.VITE_KEYCLOAK_CLIENTID)) {
  adminItem.value.push({
      label: "Upload Documents",
      icon: "pi pi-file",
      command: to('/document-upload')
    })
  }
})

async function fetchCompaniesAsync() {
  selectedCompany.value = companyStore.selectedCompany
  companies.value = (await CompanyService.getApiV1CompanyGetCompanies()).companies

  if (companyStore.selectedCompany?.id == undefined || 
    !companies.value.find((x: any)  => x.id == companyStore.selectedCompany?.id)) {
    companyStore.setCompany(companies.value[0])
    selectedCompany.value = companyStore.selectedCompany
  }
  companyLength.value = companies.value.length;
}

watch(selectedCompany, () => {
  companyStore.setCompany(selectedCompany.value);
});

onMounted(() => {
  breadCrumbStore.add({ label: "Sample", to: "/test" });
  fetchCompaniesAsync();
});

const themeStore = useThemeStore();
const theme = ref();
const useTheme = () => {
  if (themeStore.state == Theme.ConDark) {
    theme.value = new URL(
      "../node_modules/@congatec/primevue-components-lib/dist/lib/theme_dark.css",
      import.meta.url,
    ).href;
  } else {
    theme.value = new URL(
      "../node_modules/@congatec/primevue-components-lib/dist/lib/theme_light.css",
      import.meta.url,
    ).href;
  }
};
useTheme();

themeStore.$subscribe(useTheme);

companyStore.$subscribe(() => {
  remountMe.value++;
});


const search = (event: { query: string; }) => {
  suggestions.value = searchItems.value.filter((item) => item.name.toLowerCase().includes(event.query.toLowerCase()));
}

useRequestErrorGuard((title: string, message: any) => {
  if (message?.response?.data) {
    let res = message.response.data;
    if (res.Error) {
      message = res.Error;
    }
  }

  toast.add({
    severity: "error",
    summary: title,
    detail: message,
    life: 3150,
  });
}, axios);


</script>
<style scoped>
.toolbarClass {
  position: sticky !important;
  z-index: 1000 !important;
  top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarClass-sm {
  position: sticky !important;
  z-index: 1000 !important;
  top: 0 !important;
  display: flex;
}


.toolbarClass-sticky {
  position: relative
}

#button-container {
  display: flex;
  align-items: flex-end !important;
}

.InputText {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 200s ease, height 200s ease !important;
}

.InputText.visible {
  opacity: 1;
  height: auto;
  overflow: visible;
}


.input-container {
  position: absolute;
  right: 0;
  left: -187px;
  top: 0;
  height: 34px;
}
</style>
<style>
body {
  margin: 0;
  padding: 0;
}

.toolbar-global-button:hover {
  color: var(--primary-color) !important;
}

#currentCompany .p-dropdown-label,
#search-bar .p-autocomplete-input {
  padding: .3rem;
}



#app-toolbar-lg .p-toolbar-group-start,
#app-toolbar-lg .p-toolbar-group-end {
  display: none;
}

#app-toolbar-lg .p-toolbar-group-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


#currentCompany_list .p-dropdown-item {
  padding: 0rem 1rem !important;
  height: 3rem !important;
}

p {
  padding: 0;
  margin: 0;
}

.pi {
  font-size: 1rem !important;
}

.social_icons .pi {
  font-size: 2rem !important
}



.p-icon {
  color: var(--primary-color)
}

.p-breadcrumb {
  border: none !important;
  padding-left: 0 !important;
}
</style>
<style>
#overlay_menu .pi {
  color: var(--primary-color) !important;
}

#search-bar-sm .p-autocomplete-input{
  width:100%;
}
</style>
