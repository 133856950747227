// this file is a simple map for sap's keys to a regular string
// this mapping is done entierly in the UI 

export const invoiceMapper = { billingDocumentCategory: { A: 'Order Invoice', B: 'Rebate settlement', C: 'Partial rebate settlement', D: 'Periodic invoice', E: 'Periodic invoice (Accrual)', F: 'Accrual', I: 'Inter-company invoice', K: 'Rebate correction', L: 'Delivery Invoice', P: 'Down payment request', R: 'Invoice list', U: 'Billing request', W: 'POS billing document', X: 'General interface', S: 'CRM Billing Document' }, overallBillingStatus: { A:  'Open', B: 'Partially processed', C: 'Completed' } };

export const orderBacklogMapper = {
  deliveryStatus: { A: 'Not yet processed', B: 'Partially processed', C: 'Completely processed' },
  overallTotalDeliveryStatus: { A: 'Not yet processed', B: 'Partially processed', C: 'Completely processed' }, salesOrderType: {
    ZKW: ' Value Contract ',
    ZL2: ' Debit Memo Request ',
    ZLB: ' Order Type Sched.Ag. ',
    ZLD: ' Loan Mat. Delivery ',
    ZLI: ' Invoice of Loan Mat. ',
    ZLR: ' Return of Loan Mat. ',
    ZLZ: ' Sched. Agr. w/ Rel. ',
    ZNCR: ' Ord.Type Returns Del ',
    ZQD: ' QM Return to Vendor ',
    ZQR: ' QM GR from Vendor ',
    ZRAR: ' RMA advanced replace ',
    ZRE: ' Returns ',
    ZRFV: ' QM Return fromVendor ',
    ZRG2: ' Credit Memo RMA ',
    ZRIC: ' IC RMA Shipment ',
    ZRL2: ' Debit Memo RMA ',
    ZRMA: ' RMA Order ',
    ZRRP: ' RMA replacement ',
    ZRSC: ' RMA Scrap & return ',
    ZRTV: ' QM Return to Vendor ',
    ZSTA: ' Standard Order ',
    ZTAS: ' Order Subsciption ',
    ZWEB: ' Standard Order ',
    SRVO: ' Sales Order (Srvce) ',
    SRVP: ' Order in Soln Qtan ',
    STAT: ' Inquiry ',
    TAF: ' Standard Order (FPl) ',
    TAM: ' Delivery Order ',
    TAV: ' Standard Order (VMI) ',
    TBOS: ' Service Entry Sheet ',
    TBSS: ' Entry of Srvc Prfrmd ',
    TSA: ' Telephone Sales ',
    UPRR: ' Used Parts Returns ',
    UUPR: ' New Parts Returns ',
    VBOS: ' Matl-Rel. Value Cont ',
    VSH1: ' Version Order ',
    WA: ' Rel. to Value Contr. ',
    WK1: ' Value Contract- Gen. ',
    WK2: ' Matl-rel. Value Cont ',
    WMP: ' WM Prod.Supply ',
    WV: ' Service and Maint. ',
    ZAG: ' Quotation ',
    ZAGS: ' Quote Subscription ',
    ZAR1: ' Asset Retirement ',
    ZDL: ' Order Type Sched.Ag ',
    MAKO: ' Dely Order Correctn ',
    MV: ' Rental Contract ',
    NL: ' Replenishment Dlv. ',
    OBLS: ' order lump sum ',
    OBOS: ' order unit ',
    OBSS: ' OBSS ',
    OR: ' Standard Order ',
    OR1: ' Standard order ',
    PLPA: ' Pendulum List Req. ',
    PLPR: ' Pendulum List Ret. ',
    PLPS: ' Pendulum List Cancel ',
    POOL: ' Pooling order ',
    PV: ' Item Proposal ',
    QBLS: ' Quotation lumpsum ',
    QBOS: ' Quotation unit BOS ',
    QCPL: ' Customer Price List ',
    QT: ' Quotation ',
    QTLV: ' Request LV/ QTO ',
    RA: ' Repair Request ',
    RA2: ' ARM In-House Repair ',
    RAF: ' Stock Inquiry ',
    RAG: ' Stock Information ',
    RAS: ' Repairs / Service ',
    RE: ' Returns ',
    RE2: ' Advanced Returns ',
    RK: ' Invoice Correct. Req ',
    RM: ' Delvy Order Returns ',
    JBCD: ' Credit Memo Request ',
    JBDM: ' Debit Memo Request ',
    JGL: ' Cred.M.Req.Ret.Good ',
    JLL: ' Deb.M.Requ.Ret.Good ',
    JOR: ' Standard order ',
    JPCD: ' Credit Memo Request ',
    JPCM: ' Credit Memo Request ',
    JPDD: ' Debit Memo Request ',
    JPDM: ' Debit Memo Request ',
    JRE: ' Standard order ',
    JREW: ' Standard order ',
    JSDC: ' Credit Memo Request ',
    JSDD: ' Debit Memo Request ',
    JSDQ: ' Shp&Deb.Qty Contract ',
    JSMC: ' Credit Memo Request ',
    KA: ' Consignment Pick-up ',
    KAZU: ' ConsignPick-up CompS ',
    KB: ' Consignment Fill-up ',
    KE: ' Consignment Issue ',
    KR: ' Consignment Returns ',
    KRZU: ' ConsignReturn CompS ',
    L2DM: ' Expense:DebitMemoR ',
    L2DP: ' Expense:Payment Req. ',
    L2W: ' Debit Memo Request ',
    L2WT: ' Debit Memo Req Value ',
    LA: ' Ret.Packaging Pickup ',
    LK: ' Sched.Agreement ExAg ',
    LKJ: ' JIT Sched Agr w/cne ',
    LN: ' Ret.Packaging Issu ',
    DHU: ' SlsDocTypeDelyHUmv ',
    DJIT: ' Order Type JIT ',
    DL: ' Order Type Sched.Ag. ',
    DL2: ' ARM Cust.Ret.Deliv. ',
    DLR: ' Ord.Type Returns Del ',
    DLRE: ' Ord.Type Returns Del ',
    DMRB: ' Standard Order ',
    DMRP: ' Standard Order ',
    DMRR: ' Standard Order ',
    DR: ' Debit Memo Request ',
    DZL: ' Dec. Dely Order Type ',
    ED: ' Issue by Ext. Agent ',
    EDKO: ' Correction f.ExtAgnt ',
    FD: ' Deliv.Free of Charge ',
    G2W: ' Credit Memo Request ',
    G2WT: ' Credit Memo Req. Val ',
    GA2: ' Credit Memo Request ',
    GCQ: ' GG Quantity Contract ',
    GCTA: ' Standard Order ',
    GK: ' Master Contract ',
    GOR: ' GG Standard Order ',
    GPLM: ' GG SW maintenance ',
    GQT: ' GG Quotation ',
    GRE: ' GG Returns ',
    GVC: ' GG Value Contract ',
    HBIN: ' Inquiry ',
    HBOR: ' Standard order ',
    HBQT: ' Quotation ',
    '01': ' Cust.Independent Req ',
    AA: ' Promotion Order ',
    AE: ' Qtn from Serv. Order ',
    AEBO: ' Standard order ',
    AEBQ: ' Offer ',
    AP: ' Project Quotation ',
    AR: ' Repair Quotation ',
    AS: ' Service Quotation ',
    AV: ' Quotation f.Contract ',
    B1: ' Reb.Credit Memo Req. ',
    B1E: ' Exptd RebateCredMe ',
    B2: ' Rebate Correctn Rqst ',
    B2E: ' Rebate P.Correctn Rt ',
    B3: ' PartRebSettl.Request ',
    B3E: ' Exp.PartRebSettl.Req ',
    B4: ' Reb.Req.f.Man.Accrls ',
    BIND: ' Indir. Sales Rebate ',
    BK1: ' Agrmt Cred.Memo Req. ',
    BK3: ' Agrmt Cred.Memo Req. ',
    BM1: ' Agrmnt Deb.Memo Req. ',
    BM3: ' Agrmnt Deb.Memo Req. ',
    BSC: ' Service Contract BDR ',
    BSVC: ' Service Confirm eBDR ',
    BSVO: ' Service Order eBDR ',
    BV: ' Cash Sale ',
    CBOS: ' Service credit sheet ',
  }
};

export const mapSapValue = (map: any) => {
  return (obj: any) => {
    for (const key in map) {
      const val = obj[key];
      if (val) {
        if (map[key][val]) {
          obj[key] = map[key][val];
        } else {
          console.warn('Unable to map ', obj[key], 'Available options: ', map[key]);
        }
      }
    }
  }
}

export const formatDate = (date: Date | string) => {
  if (!date) {
    return 'N/A';
  }
  return new Date(date).toLocaleDateString();
}

export const formatDecimal = (decimal: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    decimal,
  ).replace("$", "");
}

export const formatPaymentStatus = (status: any) => {
  if (status.type == "overdue") {
    return `Payment overdue for ${status.days} days`;
  } else if (status.type == "pending") {
    if (status.days == 0) {
      return 'Payment due today';
    } else if (status.days == 1) {
      return `Payment due in ${status.days} day`;
    }
    return `Payment due in ${status.days} days`;
  }

  return `Settled`; 
}

