import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { OpenAPI as CeresOpenApi } from "@/apis/ceresep";
import PrimeVue from "primevue/config";
import "primeflex/primeflex.css"; 
import "primevue/resources/primevue.min.css"
import 'primeicons/primeicons.css';
// import "../src/themes/md-light-indigo/theme.css";
import { createKeycloak, getActiveKeycloak } from "@congatec/authentication-lib";
import { createConComponents } from "@congatec/primevue-components-lib";
import axios from "axios";
import {createEventBroker, setActiveEventBroker} from '@congatec/eventbroker-lib'
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import Toast from "primevue/toast";
import ConfirmationService from "primevue/confirmationservice";
CeresOpenApi.BASE = import.meta.env.VITE_PROJECT_API_BASE_URL;


const eventBroker = createEventBroker();

setActiveEventBroker(eventBroker);


const pinia = createPinia();
const keycloak = createKeycloak({
    config: {
      url: import.meta.env.VITE_KEYCLOAK_URL,
      realm: import.meta.env.VITE_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID,
    },
    init: {
      onLoad: "login-required",
      silentCheckSsoRedirectUri: `${window.location.origin}/slient-sso`,
    },
    pinia,
    axios,
    eventBroker
});
const app = createApp(App);

app.directive('tooltip', Tooltip);

app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.use(ToastService)
app.use(keycloak);
app.use(ConfirmationService);
app.use(createConComponents({
  pinia, 
  /*keycloak: getActiveKeycloak(), */
  router: router,
  eventBroker: eventBroker,
}));
app.mount("#app");
