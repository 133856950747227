<template>
  <div class="flex flex-column w-full h-full min-h-screen py-2">
    <ConfirmDialog />
    <UserDialog v-if="showUserDialog" @createOrUpdate="reloadUserTable()"
      @onDialogClose="showUserDialog = false, userToEdit = undefined" :visible="showUserDialog" :user="userToEdit">
    </UserDialog>
    <div class="flex flex-column w-full mb-2">
      <Breadcrumb :home="{ label: 'Home', command: to('/') }" :model="[{ label: 'Admin' }]"
        style="background: transparent !important;" class="p-0">
      </Breadcrumb>
    </div>
    <div class="text-800 font-semibold text-3xl mb-2 text-center">Admin Portal</div>
    <div class="text-700 text-xl mb-3 text-center ">Here you can create, edit, delete Useres and their Groups as well as
      roles</div>
    <div class="flex flex-column w-full h-full align-items-center justify-content-center">
      <Toolbar class="pl-0 w-full">
        <template #start>
          <div class="flex flex-row gap-2">
            <Button label="Create User" @click="showUserDialog = true" />
          </div>
        </template>
      </Toolbar>
      <div class="flex flex-column w-full">

        <ConDataTable id="admin-table" :columns="tableColumns" filterDisplay="menu"
          :createActionMenu="createUserActionMenu" :onReload="fetchUserDataAsync" ref="userTableRef"
          :expansionColumns="tableExpansionColumns" expansionKey="companies">
              <template #expansionCol="col">
                <Column
                  class="expansion-col"
                  v-if="isSalesAdmin"
                  expander
                  :selectionMode="col.selectionMode" 
                  :exportable="false" 
                  :columnKey="col.field" 
                  :header="col.header"
                  style="width: 3rem;">
                </Column>
                <Column v-else class="expansion-col" />
              </template>
        </ConDataTable>

      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Breadcrumb from 'primevue/breadcrumb';
import { to } from "@congatec/primevue-components-lib";
import { ConDataTable } from '@congatec/primevue-components-lib';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import UserDialog from './UserDialog.vue';
import { ref } from 'vue';
import { type ListUserResponse_User, UserService } from '@/apis/ceresep';
import { useCompanyStore } from '@/stores/company-store';
import ConfirmDialog from "primevue/confirmdialog";
import { onKeycloakToken, type KeycloakService } from '@congatec/authentication-lib';
import Column from "primevue/column";

const showUserDialog = ref(false);
const companyStore = useCompanyStore();
const userToEdit = ref<ListUserResponse_User>();
const userTableRef = ref<null | typeof ConDataTable>(null);
const isSalesAdmin = ref(false);

const tableColumns = [
  {
    field: "expansionCol",
    header: "",
  },
  {
    field: "firstName",
    header: "First name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    class: 'text-left'
  },
  {
    field: "lastName",
    header: "Last name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    class: 'text-left'
  },
  {
    field: "email",
    header: "Email",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    class: 'text-left'
  },
  {
    field: "actionMenu",
    class: 'text-left'
  },
];



const tableExpansionColumns = [
  {
    field: "sapCompanyId",
    header: "Sap Id",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "name",
    header: "Company",
    dataType: "text",
    class: 'text-left'
  },
];



function reloadUserTable() {
  userTableRef.value?.forceReload();
}

function createUserActionMenu(row: any): any {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        userToEdit.value = row;

        showUserDialog.value = true;
      }
    },
    // {
    //   label:'Remove',
    //   icon: 'pi pi-times',
    //   command:()=>{
    //     confirm.require({
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       acceptClass: "p-button-info",
    //       message: `Are you sure you want to delete this user ${row.email}`,
    //       accept: async () => {
    //       }
    //     })
    //   }
    // }
  ]
}

onKeycloakToken(async (authenticationService: KeycloakService) => {
  isSalesAdmin.value = authenticationService.hasRoles(['SalesAdmin'], import.meta.env.VITE_KEYCLOAK_CLIENTID); 
})

async function fetchUserDataAsync(filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = (await UserService.getApiV1Users(
    companyStore.selectedCompany!.id,
    filters,
    sorts,
    page,
    rows
  ));

  return {
    totalCount: res.totalCount,
    data: res.users
  }
}
</script>
<style>
#admin-table th:not(.expansion-col) 
{
  width: 33% !important
}
</style>
