/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListOpportunityForecastResponse } from '../models/ListOpportunityForecastResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpportunityService {

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListOpportunityForecastResponse OK
     * @throws ApiError
     */
    public static getApiV1Opportunity(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListOpportunityForecastResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Opportunity/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

}
