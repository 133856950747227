<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    @update:visible="closeDialog" position="top" class="w-7">
    <template #default>
      <div class="flex flex-column  gap-5 w-full">
        <div class="flex flex-row gap-5 w-full">
          <div class="flex flex-column w-full">
            <label for="email">Email <span>*</span></label>
            <InputText id="email" v-model="userState.email" :value="userState.email" placeholder="Enter the Email"
              class="w-full" />
          </div>
          <div class="flex flex-column w-full">
            <label for="password">Password <span>*</span></label>
            <Password id="password" v-model="userState.temporaryPassword" :value="userState.temporaryPassword"
              placeholder="Enter the Password" class="w-full" toggleMask/>
          </div>
        </div>
        <div class="flex flex-row gap-5 w-full">
          <div class="flex flex-column w-full">
            <label for="firstname">Firstname <span>*</span></label>
            <InputText id="firstname" v-model="userState.firstName" :value="userState.firstName"
              placeholder="Enter the Firstname" class="w-full" />
          </div>
          <div class="w-full">
            <label for="lastname">Lastname <span>*</span></label>
            <InputText id="lastname" v-model="userState.lastName" :value="userState.lastName"
              placeholder="Enter the Lastname" class="w-full" />

          </div>
        </div>
        <div class="flex flex-column w-full gap-2">
          <div v-if="salesAdmin">
            <ConDetailsItem divider icon="pi pi-building" title="Companies" />
            <AutoComplete id="company-input" v-model="companyQuery" :suggestions="companiesFound"
              @item-select="addCompany" class="w-full mb-3" placeholder="Enter company name" optionLabel="identifier" />
            <Listbox :options="userState.companies" multiple optionLabel="name" class="w-full">
              <template #option="slotProps">
                <div class="flex flex-row justify-content-between align-items-center">
                  <div>{{ slotProps.option.name }} ({{ slotProps.option.sapCompanyId }})</div>
                  <Button class="mr-5 " text rounded icon="pi pi-trash"
                    @click="removeCompany(slotProps.option.id)"></Button>
                </div>
              </template>
            </Listbox>
          </div>
        </div>
        <div class="flex flex-column w-full">
          <PickList id="user-role-picklist" v-model="userRoles" dataKey="id" breakpoint="1400px">
            <template #sourceheader> Available Roles </template>
            <template #targetheader> Assigned Roles </template>
            <template #item="slotProps">
              <div class="flex flex-wrap p-2 align-items-center gap-3 w-full h-full">
                <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{ slotProps.item.name }}</span>
                  <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-sm"></i>
                    <span>{{ slotProps.item.description }}</span>
                  </div>
                </div>
              </div>
            </template>
          </PickList>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-1 align-items-end justify-content-between">
        <Button class="p-button-text" label="Cancel" @click="() => {
          closeDialog();
        }" />
        <Button class="p-button-primary" label="Save" :onClick="createOrUpdateUser" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { onMounted, ref, watch } from 'vue';
import { type AssignableRolesListRole, UserService, type ListUserResponse_User, type ListCompaniesResponse_Company, CompanyService } from '@/apis/ceresep';
import PickList from 'primevue/picklist';
import { useCompanyStore } from '@/stores/company-store';
import { useToast } from 'primevue/usetoast';
import { delay, useDelayTimer, ConDetailsItem } from '@congatec/primevue-components-lib';
import AutoComplete from 'primevue/autocomplete';
import Listbox from 'primevue/listbox';
import { type KeycloakService, onKeycloakToken } from '@congatec/authentication-lib';
import Password from 'primevue/password';

const dialogVisible = ref(false);
const headerText = ref("Create User")
const companyStore = useCompanyStore();
const toast = useToast();
const companyQuery = ref("");
const companyQueryTimer = useDelayTimer();
const companiesFound = ref<any[]>([]);
const salesAdmin = ref(false);


onKeycloakToken(async (authenticationService: KeycloakService) => {
  salesAdmin.value = authenticationService.hasRole('SalesAdmin', import.meta.env.VITE_KEYCLOAK_CLIENTID)
})

onMounted(async () => {
  userRoles.value[0] = ((await UserService.getApiV1UsersRoles()).data || []);
  if (props.user && props.user.roles) {
    userRoles.value[1] = userRoles.value[0].filter(x => props.user!.roles!.map((y: any) => y.id).includes(x.id))
    userRoles.value[0] = userRoles.value[0].filter(x => !userRoles.value[1].map(y => y.id).includes(x.id))
  }
})

const userState = ref<UserType>({
  id: undefined,
  firstName: '',
  lastName: '',
  email: '',
  temporaryPassword: '',
  userSubjectId: '',
  roles: [],
  companies: []
});


type UserType = {
  id: number | undefined,
  firstName: string,
  lastName: string,
  email: string,
  temporaryPassword: string,
  userSubjectId: string,
  roles: number[],
  companies: ListCompaniesResponse_Company[]
}
//props definition
const props = defineProps<{
  user?: ListUserResponse_User,
  visible: boolean,
}>();

const emits = defineEmits(['onDialogClose', 'createOrUpdate']);

const closeDialog = () => {
  dialogVisible.value = false;
  emits('onDialogClose');
}

const userRoles = ref<[AssignableRolesListRole[], AssignableRolesListRole[]]>([[], []]);
//watch area
watch(() => props.visible, async (nv: any) => {
  if (nv != undefined) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });10444

watch(() => props.user, (nv) => {
  if (nv != undefined) {
    headerText.value = 'Edit User';
    userState.value.id = props.user?.id || undefined,
      //userState.value.roles = props.user?.roles,
      userState.value.firstName = props.user?.firstName || '',
      userState.value.companies = props.user?.companies || [];
    userState.value.lastName = props.user?.lastName || '',
      userState.value.email = props.user?.email || '',
      userState.value.userSubjectId = props.user?.subjecId || '',
      userState.value.temporaryPassword = ''
  }

}, { deep: true, immediate: true });

watch(companyQuery, async () => {
  delay(companyQueryTimer, async () => {
    if (!companyQuery.value) {
      companiesFound.value = [];
      return;
    }

    companiesFound.value = (await CompanyService.getApiV1CompanyList(`companyName|sapCompanyId@=${companyQuery.value}`, "companyName", 1, 10)).companies || [];

    for (let company of companiesFound.value) {
      if (company.name) {
        company.identifier = `${company.sapCompanyId}: ${company.name}`;
      } else {
        company.identifier = `${company.sapCompanyId}`
      }
    }
  });

});

const addCompany = (item: any) => {
  userState.value.companies.push(item.value)
}

const removeCompany = (id: any) => {
  let index = userState.value.companies.findIndex(x => x.id === id)
  userState.value.companies.splice(index, 1);
}

const createOrUpdateUser = async () => {
  try {
    userState.value.roles = userRoles.value[1].map((x: any) => x.id || 0);
    let companiesIds = []
    if (userState.value.companies) {
      companiesIds = userState.value.companies.map((x: any) => x.id);
    } else {
      companiesIds.push(companyStore.selectedCompany?.id)
    }

    if (props.user) {

      await UserService.putApiV1Users(companyStore.selectedCompany!.id, {
        userSubjectId: userState.value.userSubjectId,
        email: userState.value.email,
        firstName: userState.value.firstName,
        lastName: userState.value.lastName,
        temporaryPassword: userState.value.temporaryPassword,
        roles: userState.value.roles
      });
      
      toast.add({ severity: 'info', summary: 'Success', detail: 'User updated', life: 3000 });
      emits('createOrUpdate');
      closeDialog();

    } else {
      let user = await UserService.postApiV1Users(companyStore.selectedCompany!.id, {
        firstName: userState.value.firstName,
        lastName: userState.value.lastName,
        email: userState.value.email,
        temporaryPassword: userState.value.temporaryPassword,
        roles: userState.value.roles,
      })
      userState.value.id = user.id
      toast.add({ severity: 'info', summary: 'Success', detail: 'User added', life: 3000 });
      emits('createOrUpdate');
      closeDialog();
    }
    if(salesAdmin.value){
      await UserService.putApiV1UsersAssignCompany({
        companies: companiesIds,
        id: userState.value.id
      });
      toast.add({ severity: 'info', summary: 'Success', detail: 'Companies assigned', life: 3000 });
    }
  } catch (exception: any) {
    let message = 'Unknown error';
    if (exception.body) {
      message = exception.body;
      // FIXME: this can be simplified once we have the endpoint reowork
      if (exception.body.Error) {
        message = JSON.parse(exception.body.Error.substring(exception.body.Error.indexOf("{"), exception.body.Error.length)).Error;
      }
    }
  }

}

</script>
<style>
#user-role-picklist .p-picklist-source-controls,
#user-role-picklist .p-picklist-target-controls {
  display: none !important;
}

#user-role-picklist .p-picklist{
  height: 100% !important;
  min-height: 100% !important;
}

#user-role-picklist .p-picklist-list-wrapper{
  min-height: 100% !important;
}

#user-role-picklist  ul {
  min-height: 100% !important;
}

#user-role-picklist .p-button {
  background: var(--surface-300)
}

#user-role-picklist .p-disabled .p-icon {
  color: var(--surface-400)
}

#company-input .p-autocomplete-input {
  width: 100% !important;
}

#password .p-password-input{
  width: 100% !important;
}
</style>
