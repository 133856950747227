import { ref } from "vue";

export enum UserRoles {
  Admin = "Admin",
  User = "User",
  SalesAdmin = "SalesAdmin",
  ListCompanies = "ListCompanies",
  KeyAccountUser = "KeyAccountUser",
  SendEmail = "SendEmail",
  ListSampleAvailability = "ListSampleAvailability",
  ListOpportunities = "ListOpportunities",
  ListOrderBacklog = "ListOrderBacklog",
  ListPaymentHistory = "ListPaymentHistory",
  ListOpenInvoices = "ListOpenInvoices",
  PricelistDownload = "PricelistDownload",
  DownloadRoadmap = "DownloadRoadmap",
}

export const financeRoles = ref<UserRoles[]>([
  UserRoles.ListPaymentHistory,
  UserRoles.ListOpenInvoices,
]);
export const warehouseRoles = ref<UserRoles[]>([
  UserRoles.ListSampleAvailability,
]);
export const techSuppRoles = ref<UserRoles[]>([]);
export const qualityRoles = ref<UserRoles[]>([]);
export const salesRoles = ref<UserRoles[]>([
  UserRoles.ListOrderBacklog,
  UserRoles.ListOpportunities,
]);
export const marketingRoles = ref<UserRoles[]>([
  UserRoles.DownloadRoadmap
]);
export const legalRoles = ref<UserRoles[]>([]);
