<!-- eslint-disable vue/multi-word-component-names -->
// eslint-disable-next-line vue/multi-word-component-names
<template>
  <div class="mainContent" style="min-height:400px;">
    <div class="flex flex-column w-full mb-3">
      <Breadcrumb :home="home" :model="breadcrumb" style="background: transparent !important; ">
        <template #item="{ item }">
          <routerLink v-if="item.route" v-slot="{ navigate }" :to="item.route" custom>
            <a @click="navigate">
              <span>{{ item.label }}</span>
            </a>
          </routerLink>
        </template>
      </Breadcrumb>
    </div>
    <h2>{{ tableTitle }}</h2>
    <span class="grid w-full mb-5">
      <ConDataTable :title="' '" :columns="tableColumns"
        class="sm:flex-order-1 lg:flex-order-0 md:flex-order-1 xl:flex-order-0 flex-order-1" :class="{
          'md:col-12 sm:col-12 col-12 xl:col-12 lg:col-12': !shouldFetchInvoiceTotal,
          'md:col-12 sm:col-12 col-12 xl:col-10 lg:col-10': shouldFetchInvoiceTotal
        }" :expansionColumns="tableExpansionColumns" filterDisplay="menu" :rowsPerPageOptions="rowsPerPage"
        :onReload="waitForCompany" ref="tableRef" :expansionKey="expansionKey" :paginator="!shouldFetchInvoiceTotal">
        <!-- :createActionMenu="createVisitActionMenu"> -->
        <template #paymentStatus="col">
          <Column class="text-right" headerClass="column-text-right" :field="col.field" :header="col.header">
            <template #body="prop">
              <span class="text-right" :class="getPaymentStatusClass(prop.data[col.field])">
                <div class="text-right pi pi-circle-fill" />
                {{ formatPaymentStatus(prop.data[col.field]) }}
              </span>
            </template>
          </Column>
        </template>
      </ConDataTable>
      <Card
        class="md:col-12 lg:col-2 xl:col-2 col-12 surface-overlay shadow-6 sm:col-12 flex-order-0 md:flex-order-0 lg:flex-order-1 xl:flex-order-1 sm:flex-order-0"
        v-if="shouldFetchInvoiceTotal">
        <template #title>Overview</template>
        <template #content>
          <span v-if="!invoiceTotalLoading">
            <span class="flex justify-content-between flex-row">
              <div class="font-semibold">
                Debitor-Id
              </div>
              <div>
                {{ companyStore.selectedCompany?.sapCompanyId }}
              </div>
            </span>

            <span class="flex justify-content-between flex-row">
              <div class="font-semibold">
                Debitor
              </div>
              <div class="text-overflow-ellipsis text-right pl-3">
                {{ companyStore.selectedCompany?.companyName }}
              </div>
            </span>

            <span v-if="invoiceTotal.totals">
              <h3>Total</h3>
              <div class="flex justify-content-between flex-row" v-for="(total, currency) in invoiceTotal.totals"
                v-bind:key="currency">
                <div class="font-semibold">
                  {{ currency }}
                </div>
                <div>
                  {{ formatDecimal(total) }}
                </div>
              </div>
            </span>
          </span>
          <span v-else>
            <ProgressSpinner />
          </span>
        </template>
      </Card>

    </span>
  </div>
</template>
<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import { ref, onMounted } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import { useCompanyStore } from '@/stores/company-store';
import { sleepAsync } from '@/shared/sleep';
import { SapService } from "@/apis/ceresep";
import { formatPaymentStatus, formatDate, formatDecimal, invoiceMapper, mapSapValue } from "@/text/sap";
import Card from 'primevue/card';
import ProgressSpinner from 'primevue/progressspinner';
import Column from 'primevue/column';

const companyStore = useCompanyStore();

const tableRef = ref<null | typeof ConDataTable>(null);
const invoiceTotal = ref<any>({ totals: [] });
const invoiceTotalLoading = ref(false);

const rowsPerPage = ref([10, 20, 50]);

companyStore.$subscribe(async () => {
  tableRef.value?.forceReload();
  await fetchInvoiceTotal();
});

onMounted(async () => {
  await fetchInvoiceTotal();
});

const props = defineProps<{
  defaultFilter: string,
  tableTitle: string,
  shouldFetchInvoiceTotal: boolean
}>();

const breadcrumb = [
  { label: props.tableTitle, route: "/invoices" },
];
const expansionKey = "items";
const tableColumns = [
  {
    expander: true,
    field: "expansion",
    header: "",
  },
  {
    field: "billingDocument",
    header: "Invoice Nr.",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    path: "to_BillingDocument/",
    class: 'text-left'
  },
  {
    field: "documentReferenceId",
    header: "Delivery Note",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    class: 'text-left'
  },
  {
    field: "assignmentReference",
    header: "Customer Reference",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    class: 'text-left'
  },
  {
    field: "billingDocumentDate",
    header: "Invoice Date",
    dataType: "date",
    onFormat: formatDate,
    isFiltered: true,
    sortable: true,
    path: "to_BillingDocument/",
    class: 'text-left'
  },
  {
    field: "dueDate",
    header: "Due Date",
    dataType: "date",
    onFormat: formatDate,
    path: "to_BillingDocument/",
    isFiltered: false,
    sortable: false,
    class: 'text-left',
  },
  {
    field: "paymentStatus",
    header: "Payment Status",
    dataType: "text",
    onFormat: formatPaymentStatus,
    path: "to_BillingDocument/",
    class: 'text-left',
    headerClass: 'column-text-right',
  },
  {
    field: "totalGrossAmount",
    header: "Total",
    dataType: "numeric",
    onFormat: formatDecimal,
    class: 'text-right',
    headerClass: 'column-text-right',
    path: "to_BillingDocument/",
  },
  {
    field: "transactionCurrency",
    header: "Currency",
    dataType: "numeric",
    isFiltered: false,
    sortable: false,
    path: "to_BillingDocument/",
    class: 'text-left'
  },
];
const tableExpansionColumns = [
  {
    field: "material",
    header: "Material",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "billingQuantity",
    header: "Quantity",
    dataType: "text",
    class: 'text-left'
  },
  {
    field: "netAmount",
    header: "Net Amount",
    dataType: "numeric",
    class: 'text-right',
    onFormat: formatDecimal,
  },
  {
    field: "taxAmount",
    header: "Tax",
    dataType: "numeric",
    onFormat: formatDecimal,
    class: 'text-right',
  },
  {
    field: "transactionCurrency",
    header: "Currency",
    dataType: "numeric",
    class: 'text-left'
  },
];


async function fetchAsync(
  filters?: string,
  sorts?: string,
  page?: number,
  rows?: number
): Promise<any> {
  if (!sorts) {
    sorts = "-billingDocumentDate";
  }

  if (props.shouldFetchInvoiceTotal) {
    page = 1;
    rows = 0xFFFF;
  }

  const company = useCompanyStore();

  if (props.defaultFilter) {
    filters = `${props.defaultFilter},${filters}`;
  }

  let res: any = {};
  if (props.shouldFetchInvoiceTotal) {
    res = await SapService.getApiV1SapInvoiceOpen(
      company.selectedCompany?.id!,
      filters,
      sorts,
      page,
      rows,
      props.shouldFetchInvoiceTotal
    );
  } else {
    res = await SapService.getApiV1SapInvoiceHistory(
      company.selectedCompany?.id!,
      filters,
      sorts,
      page,
      rows,
      props.shouldFetchInvoiceTotal
    );
  }
  res.invoices?.forEach(mapSapValue(invoiceMapper));


  if (props.shouldFetchInvoiceTotal) {
    rowsPerPage.value = [res.totalCount ?? 1000];
  }

  return {
    totalCount: res.totalCount,
    data: res.invoices,
  };
}



const home = ref({
  label: 'Home',
  route: '/',
});


const waitForCompany = async (filters: string, sorts: string, page: number, rows: number) => {
  while (companyStore.selectedCompany == undefined) {
    console.info("Waiting for company id selection...");
    await sleepAsync(100);
  }
  return await fetchAsync(filters, sorts, page, rows);
}

const fetchInvoiceTotal = async () => {
  if (!props.shouldFetchInvoiceTotal) {
    return;
  }
  try {
    invoiceTotalLoading.value = true;
    const company = useCompanyStore();
    invoiceTotal.value = await SapService.getApiV1SapInvoiceOpenTotal(company.selectedCompany?.id!);
  } finally {
    invoiceTotalLoading.value = false;
  }
}

const getPaymentStatusClass = (status: any) => {
  if (status.type == "overdue") {
    return `text-red-500`;
  } else if (status.type == "pending" && status.days <= 7) {
    return `text-yellow-500`;
  } else if (status.type == "pending") {
    return '';
  }

  return 'text-green-500';
}

</script>
<style scoped>
a {
  cursor: pointer;
}
</style>
