/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignableRolesListRequest } from '../models/AssignableRolesListRequest';
import type { AssignableRolesListResponse } from '../models/AssignableRolesListResponse';
import type { CreateUserRequestBody } from '../models/CreateUserRequestBody';
import type { CreateUserResponse } from '../models/CreateUserResponse';
import type { EditUserCompanyAssignRequest } from '../models/EditUserCompanyAssignRequest';
import type { EditUserCompanyAssignResponse } from '../models/EditUserCompanyAssignResponse';
import type { EditUserRequestBody } from '../models/EditUserRequestBody';
import type { EditUserResponse } from '../models/EditUserResponse';
import type { ImportKeycloakUserResponse } from '../models/ImportKeycloakUserResponse';
import type { ListUserResponse } from '../models/ListUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param query 
     * @returns ImportKeycloakUserResponse OK
     * @throws ApiError
     */
    public static getApiV1UsersImport(
query?: string,
): CancelablePromise<ImportKeycloakUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/import',
            query: {
                'Query': query,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListUserResponse OK
     * @throws ApiError
     */
    public static getApiV1Users(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param companyId 
     * @param requestBody 
     * @returns CreateUserResponse OK
     * @throws ApiError
     */
    public static postApiV1Users(
companyId: number,
requestBody?: CreateUserRequestBody,
): CancelablePromise<CreateUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId 
     * @param requestBody 
     * @returns EditUserResponse OK
     * @throws ApiError
     */
    public static putApiV1Users(
companyId: number,
requestBody?: EditUserRequestBody,
): CancelablePromise<EditUserResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns AssignableRolesListResponse OK
     * @throws ApiError
     */
    public static getApiV1UsersRoles(
request?: AssignableRolesListRequest,
): CancelablePromise<AssignableRolesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/roles',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EditUserCompanyAssignResponse OK
     * @throws ApiError
     */
    public static putApiV1UsersAssignCompany(
requestBody?: EditUserCompanyAssignRequest,
): CancelablePromise<EditUserCompanyAssignResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/assign-company',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
