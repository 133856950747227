import HomeView from "@/components/home/views/HomeView.vue";
import { ConSso } from "@congatec/primevue-components-lib";
import { createRouter, createWebHistory } from "vue-router";
import Invoices from "@/components/3rdLevelPages/Invoices.vue";
import SamplesAvailabilityTable from "@/components/3rdLevelPages/SamplesAvailabilityTable.vue";
import ContactPage from "@/components/contact/ContactPage.vue";
import OpportunityForecast from "@/components/opportunity/OpportunityForecast.vue";
import AdminView from "@/components/admin/AdminView.vue";
import OrderBacklog from "@/components/order/OrderBacklog.vue";
import PricelistDownload from "@/components/pricelist/PricelistDownload.vue";
import RoadmapList from "@/components/roadmap/RoadmapList.vue";
import DocumentUpload from "@/components/admin/DocumentUpload.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/admin",
      name: "Admin",
      component: AdminView,
    },
    {
      path: "/document-upload",
      name: "Document Upload",
      component: DocumentUpload,
    },
    {
      path: "/silent-sso",
      name: "silent-sso",
      component: ConSso,
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactPage,
    },

    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Financials            ==================
      ==================************************************==================
      ==================************************************==================
    */
    {
      path: "/invoices",
      name: "Payment History",
      component: Invoices,
      props: {
        tableTitle: "Payment History",
        defaultFilter: "",
        shouldFetchInvoiceTotal: false
      }
    },
    {
      path: "/open-invoices",
      name: "OpenInvoices",
      component: Invoices,
      props: {
        tableTitle: "Open Invoices",
        defaultFilter: "",
        shouldFetchInvoiceTotal: true 
      }
    },
    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Warehouse             ==================
      ==================************************************==================
      ==================************************************==================
    */
    {
      path: "/sample-availability",
      name: "SamplesAvailability",
      component: SamplesAvailabilityTable,
    },
    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Support               ==================
      ==================************************************==================
      ==================************************************==================
    */
    // {
    //   path: "/rmas",
    //   name: "Rmas",
    //   rip rmas for now long live the king
    // },
    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Quality               ==================
      ==================************************************==================
      ==================************************************==================
    */

    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Sales                 ==================
      ==================************************************==================
      ==================************************************==================
    */
    {
      path: "/opp",
      name: "Opportunity Forecast",
      component: OpportunityForecast,
    },
    {
      path: "/order-backlog",
      name: "OrderBacklog",
      component: OrderBacklog,
    },
    {
      path: "/price-lists",
      name: "PricelistDownload",
      component: PricelistDownload,
    },
    {
      path: "/roadmaps",
      name: "Roadmap",
      component: RoadmapList,
    },

    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Marketing             ==================
      ==================************************************==================
      ==================************************************==================
    */
    /*
      ==================************************************==================
      ==================************************************==================
      ==================              Legal                 ==================
      ==================************************************==================
      ==================************************************==================
    */
  ],
});
export default router;
