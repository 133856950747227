<template>
  <div class="flex grid w-full justify-content-center">
    <div class="flex flex-grid mainContent">
      <div class="flex flex-column w-full mb-3">
        <Breadcrumb class="mb-3" :home="{ label: 'Home', command: to('/') }"
          :model="[{ label: 'Contact', command: to('/contact') }]" style="background: transparent !important; ">
        </Breadcrumb>
      </div>
      <div
        class="flex-column grid justify-content-center align-items-center gap-3 w-full lg:p-0 sm:hidden lg:flex md:flex"
        id="normalLayout">

        <div class="flex flex-row w-full lg:gap-8 sm:gap-1 gap-1">
          <div class="flex flex-column w-full">
            <label for="region" class="text-left mb-1">Contact Person</label>
            <Dropdown :loading="contactsLoading"
              :class="{ 'input-invalid': v$.contactPerson.$invalid && v$.contactPerson.$error, 'h-3rem': true, 'text-left': true, 'align-items-center': true, 'mb-3': true }"
              id="contactPerson" optionLabel="label" placeholder="Please Select" v-model="requiredFields.contactPerson"
              :options="contactPersons" />
            <label for="subject" class="text-left mb-1">Subject</label>
            <InputText id="subject" placeholder="" v-model="requiredFields.subject"
              :class="{ 'input-invalid': v$.subject.$invalid && v$.subject.$error, 'h-3rem': true, 'mb-3': true }">
            </InputText>
            <label for="message" class="text-left mb-1">Message</label>
            <Textarea rows="10" cols="40" id="message" v-model="message"></Textarea>
          </div>
        </div>
      </div>

      <div class="flex w-full justify-content-left align-items-left mt-5">
        <Button :loading="emailSending" label="Submit" @click="submitForm"></Button>
      </div>
      </div>
  </div>
</template>
<script setup lang="ts">
import Card from 'primevue/card';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
// import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea';
import { useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import Dropdown from 'primevue/dropdown'
import { countries } from 'countries-list'
import { to } from '@congatec/primevue-components-lib';
import { EmailService, type ContactsResponse_Person } from '@/apis/ceresep';
import { useCompanyStore } from '@/stores/company-store';
const router = useRouter();

const contactsLoading = ref(true);
const emailSending = ref(false);
const company = useCompanyStore();

//menuItems
const contactPersons = ref<ContactsResponse_Person[]>([
])

onMounted(async () => {
  contactPersons.value = (await EmailService.getApiV1EmailContacts(
    company.selectedCompany?.id || 0
  )).contacts || [];

  contactPersons.value.forEach((x: ContactsResponse_Person) => {
    if (x.role == 'generalSupport') {
      (x as any).label = `Support`
    } if (x.role == 'isa') {
      (x as any).label = 'Customer Service'
    } else {
      (x as any).label = `${x.role?.toString().toUpperCase()}`;
    }
    (x as any).label = `${(x as any).label}: ${x.firstName} ${x.lastName} (${x.email})`
  });

  contactsLoading.value = false;
})



//userInput
const message = ref('');


//required fields
const requiredFields = ref({
  subject: '',
  contactPerson: {} as ContactsResponse_Person,
})


const validations = {
  subject: { required },
  contactPerson: { required },
}

const v$ = useVuelidate(validations, requiredFields as any)
const cards = ref(
  [
    {
      icon: '/subsidiaries/San-Diego.jpg', title: 'San Diego, USA | congatec Inc. ', content: {
        street: '6262 Ferris Square',
        city: 'San Diego',
        postal: 'CA 92121',
        country: 'USA',
        phone: '+1 858-457-2600 ',
        fax: '+1 858-457-2602'
      }, route: ''
    },

    {
      icon: '/subsidiaries/taipei.jpg', title: 'Taipei, TW | congatec Asia Ltd.  ', content: {
        street: '2F., No.186, Sec. 3, Chengde Rd., ',
        city: 'Datong Dist.',
        postal: '10366 Taipei City',
        country: 'Taiwan',
        phone: '+886 2 25978577',
        fax: '+886 2 25978578'
      }, route: ''
    },
    {
      icon: '/subsidiaries/tokyo.jpg', title: 'Tokyo, JP | congatec Japan K.K.   ', content: {
        street: 'Hamamatsucho 1-Chome Building, ',
        city: 'Hamamatsucho 1-2-7,',
        postal: 'Minato-ku, 105-0013 Tokyo-to',
        country: 'Japan',
        phone: '+81 3 6435 9250',
        fax: '+81 3 6435 9251'
      }, route: ''
    },
    {
      icon: '/subsidiaries/shanghei.jpg', title: 'Shanghai, CN | congatec China Technology Ltd. ', content: {
        street: 'Sunyoung Center, 901 Building B, No. 28 Xuanhua Road,',
        city: 'Changning District,',
        postal: 'Shanghai 200050,',
        country: 'China',
        phone: '+86 21 60255862',
        fax: '+86 21 60256561'
      }, route: ''
    },
    {
      icon: '/subsidiaries/brisbane.jpg', title: 'West Burleigh, AU | congatec Australia Pty Ltd.', content: {
        street: 'Unit 2,62, Township Drive',
        city: 'West Burleigh',
        postal: 'Queensland 4219',
        country: 'Australia',
        phone: '+61 755200841',
      }, route: ''
    },
    {
      icon: '/subsidiaries/seongnam.jpg', title: 'West Burleigh, AU | congatec Australia Pty Ltd.', content: {
        street: 'Leaders building #707, 42 Jangmi-ro, Bundan-gu,',
        city: 'Seongnam-si, Gyeonggi-do',
        postal: '13496 South Korea',
        country: 'South Korea',
        phone: '+82 (10) 2715-6418',
      }, route: ''
    },
    {
      icon: '/subsidiaries/byrno.jpg', title: 'Brno, CZ | congatec sro', content: {
        street: 'Lidická 2030/20 ',
        postal: '602 00 Brno',
        country: 'Czech Republic',
        phone: '+1 858-457-2600',
      }, route: ''
    },
  ])



//functions
function submitForm() {
  v$.value.$validate()
  if (v$.value.$error) {
    return;
  } else {
    sendEmailAsync();
  }
}

function getCountriesList() {
  const result: any[] = []
  const countryCodes = Object.keys(countries);
  countryCodes.map(code => result.push({ code: code, name: (countries as any)[code].name }));
  return result.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0);
}
//api-calls
async function sendEmailAsync() {
  try {
    console.log(requiredFields.value.contactPerson);
    emailSending.value = true;
    await EmailService.postApiV1EmailSendEmail(
      company.selectedCompany?.id || 0,
      {
        subject: requiredFields.value.subject,
        body: message.value,
        contact: requiredFields.value.contactPerson.role
      })
  } finally {
    emailSending.value = false;
  }
}


</script>
<style scoped>
.grid {
  margin: 0;
}

a {
  color: var(--primary-color)
}

p {
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #46464b !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.contact-Page-Card {
  cursor: pointer;
  padding: 0%;
  height: 100%;
  max-width: 360px;
  min-width: 360px;
  width: 100%;
  min-height: 586px;
  height: 100%;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
  border-spacing: 0;
  padding: 0;
  font-family: "Adelle W02 SemiBold", "Times New Roman", Times, serif !important;
}

.p-card {
  box-shadow: none;

}

.card-bck-img {
  background-position: center;
  height: 85px;
  background-repeat: no-repeat;
}

#cardContainer {
  /* gap: 30px !important; */
  padding-top: 40px;
  min-width: 100vw !important;
  /* min-width: 1170px !important; */
}

@media (min-width:1170px) {
  #cardContainer {
    min-width: 1170px !important;
  }

}

.p-card .p-card-content {
  padding: 0 !important;

}

.p-card-body {
  padding: 0 !important;

}

.input-invalid {
  border: 1px solid var(--primary-color);
}

.input-invalid:hover {
  border: 1px solid var(--primary-color) !important;
}

span {
  color: var(--primary-color);
  font-weight: 600;
}



label {
  font-weight: 600;
}
</style>
