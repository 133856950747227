<template>
  <div class="mainContent" style="min-height: 400px">
    <div class="flex flex-column w-full mb-3">
      <Dialog
        v-model:visible="showForecast"
        modal
        :draggable="false"
        header="Opportunity Forecast"
        position="top"
        style="max-width: 80%; min-width: 80%; max-height: 80%"
      >
        <template #default>
          <div class="card flex justify-content-left">
            <div class="flex flex-column gap-5 w-full">
              <div class="flex flex-row mt-3 gap-5 w-full">
                <ConDataTable
                  class="w-full mb-5 px-0"
                  :rowsPerPageOptions="[selectedRow.forecast.length]"
                  :columns="tableForecastColumns"
                  :onReload="
                    () => {
                      return {
                        totalCount: selectedRow.forecast.length,
                        data: selectedRow.forecast,
                      };
                    }
                  "
                >
                </ConDataTable>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex justify-content-end gap-2">
            <Button
              type="button"
              label="Ok"
              @click="showForecast = false"
            ></Button>
          </div>
        </template>
      </Dialog>
      <Breadcrumb
        :home="{ label: 'Home', command: to('/') }"
        :model="[{ label: 'Project Management', command: to('/opp') }]"
        style="background: transparent !important"
      ></Breadcrumb>
    </div>
    <ConDataTable
      tableId="OpportunityList"
      :isLoading="isLoading"
      title="Project Management"
      class="w-full mb-5"
      filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]"
      ref="tableRef"
      expansionKey="details"
      :columns="tableColumns"
      :expansionColumns="tableExpansionColumns"
      :onReload="fetchOpportunityForecastDataAsync"
    >
      <template #details="col">
        <Column :field="col.field" :header="col.header">
          <template #body="propMatching">
            <div class="flex justify-content-center">
              <Button
                icon="pi pi-eye"
                :onClick="
                  () => {
                    showForecastDialog(propMatching.data);
                    showForecast = true;
                  }
                "
              >
              </Button>
            </div>
          </template>
        </Column>
      </template>
    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import Breadcrumb from "primevue/breadcrumb";
import { ConDataTable } from "@congatec/primevue-components-lib";
import { to } from "@congatec/primevue-components-lib";
import { type Ref, ref } from "vue";
import { useCompanyStore } from "../../stores/company-store";
import { OpportunityService } from "../../apis/ceresep";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import Button from "primevue/button";

const companyStore = useCompanyStore();
const tableRef = ref<null | typeof ConDataTable>(null);

let isLoading = ref(false);
const showForecast = ref(false);
const selectedRow: Ref<any> = ref(null);

companyStore.$subscribe(async () => {
  tableRef.value?.forceReload();
});

let tableColumns = [
  {
    expander: true,
    field: "expansion",
    header: "",
  },
  // {
  //   selectionMode: "multiple",
  //   field: "any",
  // },
  {
    field: "name",
    header: "Opportunity Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMatchMode: "contains",
    class: "text-left",
    filterMatchModeOptions: [
      { label: "Equals", value: "equals" },
      { label: "NotEquals", value: "notEquals" },
      { label: "Contains", value: "contains" },
      { label: "NotContains", value: "notContains" },
    ],
  },
  {
    field: "sapOpportunityId",
    header: "Opportunity ID",
    dataType: "number",
    isFiltered: true,
    sortable: true,
    filterMatchMode: "equals",
    class: "text-left",
    filterMatchModeOptions: [
      { label: "Equals", value: "equals" },
      { label: "NotEquals", value: "notEquals" },
    ],
  },
  {
    field: "status",
    header: "Status",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    //filterMeta: { operator: 'and', constraints: [{ value: [], matchMode: 'equals' }]},
    filterMatchMode: "equals",
    class: "text-left",
    filterMatchModeOptions: [
      { label: "Equals", value: "equals" },
      { label: "NotEquals", value: "notEquals" },
    ],
  },
  {
    field: "stage",
    header: "Stage",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMatchMode: "equals",
    class: "text-left",
    filterMatchModeOptions: [
      { label: "Equals", value: "equals" },
      { label: "NotEquals", value: "notEquals" },
    ],
  },
  {
    field: "dwDecissionDate",
    header: "Decision Date",
    dataType: "text",
    isFiltered: false,
    sortable: false,
    class: "text-left",
  },
  {
    field: "createdOn",
    header: "Created",
    dataType: "text",
    isFiltered: false,
    sortable: true,
    class: "text-left",
  },
  {
    field: "updatedOn",
    header: "Last Updated",
    dataType: "text",
    isFiltered: false,
    sortable: false,
    class: "text-center",
  },
  {
    selectionMode: undefined,
    field: "details",
    header: "Forecast",
    class: "text-left",
  },
];

let tableExpansionColumns = [
  {
    field: "qtyFirstYearFullProd",
    header: "Quantity Per Year",
    dataType: "text",
    class: "text-left",
  },
  {
    field: "projectLifetime",
    header: "Project Lifetime (Years)",
    dataType: "text",
    class: "text-left",
  },
  {
    field: "productionDate",
    header: "Start of Production Date",
    dataType: "text",
    class: "text-left",
  },
  {
    field: "designWinValue",
    header: "Design Win Value",
    dataType: "text",
    class: "text-right",
  },
  {
    field: "targetMarket",
    header: "Target Market",
    dataType: "text",
    class: "text-left",
  },
  {
    field: "opportunityClassification",
    header: "Opportunity Classification",
    dataType: "text",
    class: "text-left",
  },
];

let tableForecastColumns = [
  {
    field: "year",
    header: "Year",
    dataType: "text",
  },
  {
    field: "productNumber",
    header: "Product Number",
    dataType: "text",
  },
  {
    field: "jan",
    header: "Jan",
    dataType: "text",
  },
  {
    field: "feb",
    header: "Feb",
    dataType: "text",
  },
  {
    field: "Mar",
    header: "Mar",
    dataType: "text",
  },
  {
    field: "apr",
    header: "Apr",
    dataType: "text",
  },
  {
    field: "may",
    header: "may",
    dataType: "text",
  },
  {
    field: "jun",
    header: "Jun",
    dataType: "text",
  },
  {
    field: "jul",
    header: "Jul",
    dataType: "text",
  },
  {
    field: "aug",
    header: "Aug",
    dataType: "text",
  },
  {
    field: "sep",
    header: "Sep",
    dataType: "text",
  },
  {
    field: "oct",
    header: "Oct",
    dataType: "text",
  },
  {
    field: "nov",
    header: "Nov",
    dataType: "text",
  },
  {
    field: "dec",
    header: "Dec",
    dataType: "text",
  },
];

async function fetchOpportunityForecastDataAsync(
  filters?: string,
  sorts?: string,
  page?: number,
  rows?: number
): Promise<any> {
  const company = useCompanyStore();
  const res = await OpportunityService.getApiV1Opportunity(
    company.selectedCompany?.id!,
    filters,
    sorts || "status",
    page,
    rows
  );
  return {
    totalCount: res.total,
    data: res.opportunities,
  };
}

function showForecastDialog(data: any) {
  if (data) {
    console.log("selectedRow", data.forecast);
    selectedRow.value = data;
  }
}
</script>
