<template>
  <div class="py-2">
    <div class="flex flex-column w-full">
      <Breadcrumb
        :home="{ label: 'Home', command: to('/') }"
        style="background: transparent !important"
        class="p-0"
      >
      </Breadcrumb>
    </div>
    <div class="text-800 font-semibold text-6xl mb-4 text-center">
      Customer & Partner Portal
    </div>
    <div class="text-700 text-xl mb-6 text-center line-height-3">
      Welcome to the congatec Unified Information Platform. <br />
      From here you can consult and manage your past and on-going operations, as
      well as contact us.
    </div>

    <div class="grid lg:justify-content-start justify-content-center">
      <!--TODO: move card into component will make access handling easier -> maybe in december ¯\_(ツ)_/¯ -->
      <div
        :class="`col-11 lg:col-${card.gridWidth}`"
        v-for="card in cards"
        v-bind:key="card.title"
      >
        <div
          :class="{
            'shadow-2': true,
            'h-full': true,
            flex: true,
            'flex-column': true,
            'surface-card': true,
            'disabled-card': !card.available,
            'border-solid': true,
            'surface-border': true,
            'border-2': true,
          }"
          style="border-radius: 6px"
        >
          <div id="cardTitle">
            <div
              :style="`background-image: url(${card.bgImgUrl}); background-size: cover; background-position: center; height:130px; border-radius:6px 6px 0 0;`"
            ></div>
            <div class="text-900 font-medium text-xl mb-2 text-center mt-2">
              {{ card.title }}
            </div>
            <div class="text-600 text-center">{{ card.description }}</div>
          </div>
          <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
          <div id="cardContent" class="px-3 flex flex-column">
            <div class="flex flex-row justify-content-center gap-2">
              <ul class="list-disc flex-column p-0 m-0 flex-grow-1 w-6">
                <li
                  class="flex align-items-center justify-content-start mb-3"
                  v-for="(item, index) in card.items.filter((item :any, index :any) => { return index % 2 == 0 })"
                  v-bind:key="index"
                  @click="router.push(item.route)"
                  :class="{
                    'cursor-pointer': item.available,
                    'disabled-card': !item.available,
                  }"
                >
                  <i
                    v-if="item.available == true"
                    class="pi pi-check-circle text-green-500 mr-2"
                  ></i>
                  <i v-else class="pi pi-times-circle text-red-500 mr-2"></i>
                  {{ item.title }}
                </li>
              </ul>
              <ul class="list-disc flex-column p-0 m-0 flex-grow-1 w-6">
                <li
                  class="flex align-items-center justify-content-end mb-3"
                  v-for="(item, index) in card.items.filter((item :any, index:any) => { return index % 2 != 0 })"
                  v-bind:key="index"
                  :class="{
                    'cursor-pointer': item.available,
                    'disabled-card': !item.available,
                  }"
                  @click="router.push(item.route)"
                >
                  <p class="w-full text-right">{{ item.title }}</p>
                  <i
                    v-if="item.available == true"
                    class="pi pi-check-circle text-green-500 ml-2"
                  ></i>
                  <i v-else class="pi pi-times-circle text-red-500 ml-2"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import { useRouter } from "vue-router";
import { to } from "@congatec/primevue-components-lib";
import { onMounted, ref } from "vue";
import Divider from "primevue/divider";
import {
  UserRoles,
  financeRoles,
  legalRoles,
  marketingRoles,
  qualityRoles,
  salesRoles,
  techSuppRoles,
  warehouseRoles,
} from "@/shared/UserRoles";
import {
  type KeycloakService,
  onKeycloakToken,
} from "@congatec/authentication-lib";

//variables for access check
const financialCardVisible = ref(false);
const warehouseCardVisible = ref(false);
const techSuppCardVisible = ref(false);
const salesCardVisible = ref(false);
const qualityCardVisible = ref(false);
const marketingCardVisible = ref(false);
const legalCardVisible = ref(false);
const paymentHistoryAccess = ref(false);
const openInvoiceAccess = ref(false);
const sampleAvailabilityAccess = ref(false);
const orderBacklogAccess = ref(false);
const oppForecastAccess = ref(false);
const pricelistAccess = ref(false);
const roadmapAccess = ref(false);
const router = useRouter();

const cards = ref<any>([]);

onKeycloakToken(async (authenticationService: KeycloakService) => {
  financialCardVisible.value = authenticationService.hasRoles(
    financeRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  warehouseCardVisible.value = authenticationService.hasRoles(
    warehouseRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  techSuppCardVisible.value = authenticationService.hasRoles(
    techSuppRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  salesCardVisible.value = authenticationService.hasRoles(
    salesRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  qualityCardVisible.value = authenticationService.hasRoles(
    qualityRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  marketingCardVisible.value = authenticationService.hasRoles(
    marketingRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  legalCardVisible.value = authenticationService.hasRoles(
    legalRoles.value,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  paymentHistoryAccess.value = authenticationService.hasRole(
    UserRoles.ListPaymentHistory,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  openInvoiceAccess.value = authenticationService.hasRole(
    UserRoles.ListOpenInvoices,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  sampleAvailabilityAccess.value = authenticationService.hasRole(
    UserRoles.ListSampleAvailability,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  orderBacklogAccess.value = authenticationService.hasRole(
    UserRoles.ListOrderBacklog,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  oppForecastAccess.value = authenticationService.hasRole(
    UserRoles.ListOpportunities,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  pricelistAccess.value = authenticationService.hasRole(
    UserRoles.PricelistDownload,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );
  roadmapAccess.value = authenticationService.hasRole(
    UserRoles.DownloadRoadmap,
    import.meta.env.VITE_KEYCLOAK_CLIENTID
  );

  cards.value.push(
    {
      bgImgUrl: "/general_images/card1.png",
      title: "Financials",
      description: "current services of our financials department",
      gridWidth: 3,
      available: financialCardVisible.value,
      routerLink: "/financials",
      items: [
        {
          title: "Payment Terms",
          available: false,
          route: "",
        },
        {
          title: "Payment History",
          available: paymentHistoryAccess.value,
          route: "/invoices",
        },
        {
          title: "Credit Limit",
          available: false,
          route: "",
        },
        {
          title: "Open Invoices",
          available: openInvoiceAccess.value,
          route: "/open-invoices",
        },
      ],
    },
    {
      bgImgUrl: "/general_images/card2.png",
      title: "Warehouse",
      description: "current services of our warehouse department",
      gridWidth: 3,
      available: warehouseCardVisible.value,
      routerLink: "/warehouse",
      items: [
        {
          title: "Lead Times",
          available: false,
          route: "",
        },
        {
          title: "Sample Availability",
          available: sampleAvailabilityAccess.value,
          route: "/sample-availability",
        },
        {
          title: "Change RDD",
          available: false,
          route: "",
        },

        {
          title: "Track Delivery",
          available: false,
          route: "",
        },
        {
          title: "Check IncoTerms",
          available: false,
          route: "",
        },
      ],
    },
    {
      bgImgUrl: "/general_images/card3.png",
      title: "Technical Support",
      description: "current services of our Support department",
      gridWidth: 3,
      available: techSuppCardVisible.value,
      routerLink: "/support",
      items: [
        {
          title: "RMAs",
          available: false,
          route: "",
        },
        {
          title: "Tickets",
          available: false,
          route: "",
        },
        {
          title: "Osadl & Online Test",
          available: false,
          route: "",
        },
        {
          title: "Tech. Documentations",
          available: false,
          route: "",
        },
        {
          title: "Benchmarks",
          available: false,
          route: "",
        },
        {
          title: "Courses & Training",
          available: false,
          route: "",
        },
      ],
    },
    {
      bgImgUrl: "/general_images/card5.png",
      title: "Quality",
      description: "current services of our Quality department",
      gridWidth: 3,
      available: qualityCardVisible.value,
      routerLink: "",
      items: [
        {
          title: "Certificates",
          available: false,
          route: "",
        },
      ],
    },
    {
      bgImgUrl: "/general_images/card4.png",
      title: "Sales",
      description: "current services of our Sales department",
      gridWidth: 4,
      available: true,
      routerLink: "/sales",
      items: [
        {
          title: "Order Backlog",
          available: orderBacklogAccess.value,
          route: "/order-backlog",
        },
        {
          title: "Price Lists",
          available: pricelistAccess.value,
          route: "/price-lists",
        },
        {
          title: "Request a Quote",
          available: false,
          route: "",
        },
        {
          title: "Project Registration",
          available: false,
          route: "",
        },
        {
          title: "Project Management",
          available: oppForecastAccess.value,
          route: "/opp",
        },
        {
          title: "Special Offers",
          available: false,
          route: "",
        },
      ],
    },
    {
      bgImgUrl: "/general_images/card6.png",
      title: "Marketing",
      description: "current services of our Marketing department",
      gridWidth: 4,
      available: marketingCardVisible.value,
      routerLink: "",
      items: [
        {
          title: "Marketing Budget",
          available: false,
          route: "",
        },
        {
          title: "Upcoming Events",
          available: false,
          route: "",
        },
        {
          title: "Register Events",
          available: false,
          route: "",
        },
        {
          title: "Roadmap(s)",
          available: roadmapAccess.value,
          route: "/roadmaps",
        },
      ],
    },
    {
      bgImgUrl: "/general_images/card7.png",
      title: "Legal",
      description: "current services of our Legal department",
      gridWidth: 4,
      available: legalCardVisible.value,
      routerLink: "",
      items: [
        {
          title: "Contracts",
          available: false,
          route: "",
        },
        {
          title: "Trade Compliance",
          available: false,
          route: "",
        },
      ],
    }
  );
});
</script>
<style>
.Card-Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Card-Button .p-button-label {
  flex: 0;
}
</style>
<style scoped>
.disabled-card {
  pointer-events: none;
  opacity: 0.6;
}
</style>
