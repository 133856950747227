<template>
    <div class="mainContent" style="min-height: 400px">
        <div class="flex flex-column w-full">
            <Breadcrumb :home="home" :model="breadcrumb" style="background: transparent !important">
                <template #item="{ item }">
                    <routerLink v-if="item.route" v-slot="{ navigate }" :to="item.route" custom>
                        <a @click="navigate">
                            <span>{{ item.label }}</span>
                        </a>
                    </routerLink>
                </template>
            </Breadcrumb>
        </div>
        <div class="grid">
            <div v-for="(item, index) in roadmaps" :key="index" class="col-6 justify-content-center align-items-center flex" :class="{'col w-full':roadmaps.length < 2}">
                <Card class="flex flex-column justify-content-center w-full">
                    <template #title>
                        <div class="flex flex-row w-full justify-content-center gap-3 align-items-center">{{ item.fileName
                        }}
                            <Button label="Download" icon="pi pi-file-pdf" @click="downloadFile(item.fileName)"></Button>
                        </div>
                    </template>
                    <template #content>
                        <div class="flex justify-content-center w-full">
                            <Carousel class="w-8" :class="{'w-full':roadmaps.length > 1}" v-if="!loadStatus[index]" :value="images[index]" :numVisible="1"
                                :numScroll="1" :responsiveOptions="responsiveOptions">
                                <template #item="slotProps">
                                    <div class="border border-surface-200 dark:border-surface-700 rounded m-2  p-4">
                                        <div class="mb-4">
                                            <img :src="'data:image/png;charset=utf-8;base64, ' + slotProps.data.content"
                                                class="w-full rounded" />
                                        </div>
                                    </div>
                                </template>
                            </Carousel>
                            <ProgressSpinner v-else />
                        </div>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>
  
<script setup lang="ts">
import Card from "primevue/card";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import { ref, onMounted, type Ref } from "vue";
import { DocumentService } from "@/apis/ceresep";
import Carousel from 'primevue/carousel';
import ProgressSpinner from 'primevue/progressspinner';


let title = ref("Roadmaps");
const roadmaps = ref();
const images = ref([[{ content: "" }]]);
const loadStatus: Ref<boolean[]> = ref([]);

const home = ref({
    label: "Home",
    route: "/",
});
const breadcrumb = [{ label: title.value, route: "/roadmaps" }];

const responsiveOptions = ref([
    {
        breakpoint: '1400px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
    }
]);


onMounted(async () => {
    await DocumentService.getApiV1Documents().then((res) => (roadmaps.value = res.roadmaps?.slice(0, 9)));

    roadmaps.value.forEach(async (element: any, index: number) => {
        loadStatus.value.push(true);
        await DocumentService.getApiV1DocumentsImages(element.fileName).then(
            (res) => {
                if (res.content) {
                    images.value[index] = res.content.map((x) => ({ content: x }))
                }
            });
        loadStatus.value[index] = false;
    })
    console.log("images: ", images.value);
})

async function downloadFile(filename: string) {
    let res = await DocumentService.getApiV1DocumentsRoadmap(filename);

    let element = document.createElement("a");
    element.setAttribute(
        "href",
        "data:" + res.mimetype + ";base64, " + encodeURIComponent(res.content!)
    );
    element.setAttribute("download", res.filename!);
    document.body.appendChild(element);
    element.click();
}
</script>
