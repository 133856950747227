/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingDocumentHistoryResponse } from '../models/BillingDocumentHistoryResponse';
import type { BillingDocumentOpenResponse } from '../models/BillingDocumentOpenResponse';
import type { BillingDocumentTotalResponse } from '../models/BillingDocumentTotalResponse';
import type { SalesOrderItemPartnerResponse } from '../models/SalesOrderItemPartnerResponse';
import type { SamplesAvailabilityResponse } from '../models/SamplesAvailabilityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SapService {

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param rmaData 
     * @returns SalesOrderItemPartnerResponse OK
     * @throws ApiError
     */
    public static getApiV1SapSalesOrder(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
rmaData?: boolean,
): CancelablePromise<SalesOrderItemPartnerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/{CompanyId}/SalesOrder',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'rmaData': rmaData,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param rmaData 
     * @returns SalesOrderItemPartnerResponse OK
     * @throws ApiError
     */
    public static getApiV1SapRmAs(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
rmaData?: boolean,
): CancelablePromise<SalesOrderItemPartnerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/{CompanyId}/RMAs',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'rmaData': rmaData,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param filterIfPaymendDateNull 
     * @returns BillingDocumentHistoryResponse OK
     * @throws ApiError
     */
    public static getApiV1SapInvoiceHistory(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
filterIfPaymendDateNull?: boolean,
): CancelablePromise<BillingDocumentHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/{CompanyId}/invoice/history',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'FilterIfPaymendDateNull': filterIfPaymendDateNull,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param filterIfPaymendDateNull 
     * @returns BillingDocumentOpenResponse OK
     * @throws ApiError
     */
    public static getApiV1SapInvoiceOpen(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
filterIfPaymendDateNull?: boolean,
): CancelablePromise<BillingDocumentOpenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/{CompanyId}/invoice/open',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'FilterIfPaymendDateNull': filterIfPaymendDateNull,
            },
        });
    }

    /**
     * @param companyId 
     * @returns BillingDocumentTotalResponse OK
     * @throws ApiError
     */
    public static getApiV1SapInvoiceOpenTotal(
companyId: number,
): CancelablePromise<BillingDocumentTotalResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/{CompanyId}/invoice/open/total',
            path: {
                'CompanyId': companyId,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns SamplesAvailabilityResponse OK
     * @throws ApiError
     */
    public static getApiV1SapSamplesAvailability(
companyId: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<SamplesAvailabilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/{CompanyId}/SamplesAvailability',
            path: {
                'CompanyId': companyId,
            },
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

}
